export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem("userData")) || {},
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
      localStorage.setItem("userData", JSON.stringify(user));
    },
  },
  actions: {
    onAuthUser: ({ commit }, user) => {
      commit("setUser", { ...user });
    },
  },
  getters: {
    userType: ({ user }) => user?.type,
    userTypeTeacher: ({ user }) => user?.type == "teacher",
    userTypeStudent: ({ user }) =>
      ["pass_student", "new_student"].includes(user?.type),
  },
};
